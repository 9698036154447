<template>
  <div>
    <v-container id="interest-table" fluid tag="section">
      <base-material-card inline color="secondary" class="px-5 py-3 mb-5">
        <template v-slot:heading><v-icon>mdi-signature</v-icon></template>
        <template v-slot:after-heading
          ><h1 class="h5">
            <b>{{ $tc("sent_notification", 2) }}</b>
          </h1></template
        >
        <v-btn
          color="primary"
          rounded
          @click="open_edit_dialog(null)"
          absolute
          small
          fab
          top
          right
          data-test="Notifications:SentNotifications:BtnCreate"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <div v-if="!loading">
          <v-form
            @submit.stop.prevent="get_notifications_by_page()"
            ref="form"
            v-model="valid"
            :lazy-validation="lazy"
          >
            <v-row justify="center" class="mt-6">
              <v-text-field
                class="mx-3"
                dense
                outlined
                :label="$t('email')"
                v-model="name_filter"
                style="max-width: 35%"
              ></v-text-field>
              <v-btn class="mx-3 secondary" type="submit">{{
                $t("apply")
              }}</v-btn>
            </v-row>
          </v-form>
          <v-simple-table>
            <thead>
              <tr>
                <th>{{ $tc("investor", 1) }}</th>
                <th>{{ $t("email") }}</th>
                <th>{{ $tc("company", 1) }}</th>
                <th>{{ $t("value") }}</th>
                <th>{{ $t("date") }}</th>
                <th>{{ $t("status") }}</th>
                <th>{{ $t("options") }}</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(n, index) in notifications" :key="index">
                <td>{{ gs.show_full_name(n.Investor) }}</td>
                <td>{{ n.Investor.Email }}</td>
                <td>{{ n.CompanyInterest.Company.Name }}</td>
                <td>
                  {{
                    formatCurrency(
                      n.CompanyInterest.Currency,
                      n.CompanyInterest.Value
                    )
                  }}
                </td>
                <td>{{ gs.convert_date(n.Timestamp, true) }}</td>
                <td>{{ StatusOptions[n.Status] }}</td>
                <td>
                  <v-btn
                    @click="open_delete_dialog(n)"
                    color="red"
                    min-width="0"
                    small
                  >
                    <v-icon small>mdi-trash-can</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-pagination
            color="secondary"
            @input="changePage"
            :length="total_pages"
            v-model="page"
          >
          </v-pagination>
        </div>
        <v-container v-else>
          <v-progress-circular
            style="margin-left: 50%"
            indeterminate
            size="70"
            color="primary"
          ></v-progress-circular>
        </v-container>
      </base-material-card>
      <v-dialog v-if="dialog" v-model="dialog">
        <AddSignatureNotification
          :Interest="request"
          @close="dialog = false"
          @update="requests_updated"
        ></AddSignatureNotification>
      </v-dialog>
      <v-dialog v-if="delete_dialog" v-model="delete_dialog">
        <DeleteConfirmationModal
          :obj="notification"
          :name="objectName"
          @close="delete_dialog = false"
          @delete="delete_notification"
        ></DeleteConfirmationModal>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import AddSignatureNotification from "../form/AddSignatureNotification.vue";
import DeleteConfirmationModal from "@/views/pages/DeleteConfirmationModal";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  name: "SentNotifications",

  components: { AddSignatureNotification, DeleteConfirmationModal },

  data: () => ({
    gs: new GeneralServices(),
    apiService: new ApiService(),
    loading: false,
    page: 1,
    total_pages: 1,
    name_filter: "",
    valid: true,
    lazy: false,
    dialog: false,
    delete_dialog: false,
    request: null,
    notification: null,
    objectName: null,
    notifications: [],
    formatCurrency: formatCurrency,
    CurrencyEnum: CurrencyEnum,
  }),
  async created() {
    this.get_notifications_by_page();
  },
  computed: {
    StatusOptions() {
      return [
        this.$t("interest_status_pending"),
        this.$t("interest_status_approved"),
        this.$t("interest_status_declined"),
        this.$t("interest_status_waiting_investor"),
      ];
    },
  },
  methods: {
    async delete_notification(notification) {
      var index = this.notifications.indexOf(notification);
      this.notifications.splice(index, 1);
      this.delete_dialog = false;
      await this.apiService.deleteRequest(
        `signaturenotification/delete/${notification.Id}`
      );
    },
    open_edit_dialog(notification) {
      if (notification) {
        this.request = notification.CompanyInterest;
      } else {
        this.request = null;
      }
      this.dialog = true;
    },
    requests_updated(request) {
      if (request == null) {
        this.get_notifications_by_page();
      } else {
        var index = this.notifications
          .map((x) => x.CompanyInterestId)
          .indexOf(request.Id);
        this.notifications[index].CompanyInterest.Value = request.Value;
      }
      this.dialog = false;
    },
    open_delete_dialog: function (notification) {
      this.delete_dialog = true;
      this.objectName = this.$t("this_notification");
      this.notification = notification;
    },
    async get_notifications_by_page() {
      this.loading = true;
      var page = this.page;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page);
      }
      var params = { Page: page };
      if (this.name_filter) {
        params.NameFilter = this.name_filter;
      }
      if (this.orderBy != null) {
        params.Order = this.orderBy;
        params.OrderMode = this.orderByMode;
      }
      await this.apiService
        .postRequest("signaturenotification/list-sent/filter", params)
        .then((resp) => {
          var json = JSON.parse(resp.message);
          this.page = json.Pager.CurrentPage;
          this.total_pages = json.Pager.TotalPages;
          this.notifications = json.Notifications;
        });
      this.loading = false;
      this.dialog = false;
    },
    changePage() {
      this.$router.push({
        path: "/signature-notifications/sent",
        query: { page: this.page },
      });
      this.get_notifications_by_page();
    },
  },
};
</script>

<template>
  <v-container id="add-investor-modal" fluid tag="section">
    <v-card class="dxa_modal">
      <v-card-title class="mb-2">
        <h4 class="dxa_modal_title h4">{{ $t("new_notification") }}</h4>
      </v-card-title>
      <v-card-text>
        <v-form @submit.stop.prevent="submit()" ref="form">
          <v-combobox
            v-model="userSelected"
            :items="users"
            :disabled="edit || users.length == 1"
            :rules="[required]"
            item-text="Email"
            return-object
            :label="$tc('user', 1)"
            @change="get_ongoing_opportunities()"
            data-test="Notifications:AddSignatureNotification:InputUser"
          ></v-combobox>

          <v-combobox
            :disabled="edit"
            v-model="companySelected"
            :items="companies"
            :rules="[required]"
            item-text="Name"
            item-value="Id"
            :label="$tc('company', 1)"
            data-test="Notifications:AddSignatureNotification:InputCompany"
          ></v-combobox>

          <v-select
            :rules="[required]"
            v-model="interest.Currency"
            :items="currencyOptions"
            item-text="text"
            item-value="value"
            :label="$t('currency')"
            dense
            data-test="Notifications:AddSignatureNotification:SelectCurrency"
          ></v-select>

          <v-text-field
            :rules="[required]"
            v-model="interest.Value"
            :label="$t('value')"
            class="pt-0"
            type="number"
            data-test="Notifications:AddSignatureNotification:InputValue"
          />

          <v-card-actions class="pl-0 dxa_modal_actions">
            <v-btn
              class="dxa_modal_btnSuccess"
              color="primary"
              min-width="100"
              :loading="loading"
              type="submit"
              data-test="Notifications:AddSignatureNotification:BtnSave"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              class="dxa_modal_btnError"
              color="red"
              min-width="100"
              @click="$emit('close', null)"
              data-test="Notifications:AddSignatureNotification:BtnCancel"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-alert class="ma-2" v-if="error" type="error">{{
          $t(error)
        }}</v-alert>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";
import { UserTypeEnum } from "@/shared/enums/UserType";

export default {
  name: "AddSignatureNotificationModal",
  data: () => ({
    apiService: new ApiService(),
    loading: false,
    existing_user: true,
    row: null,
    edit: false,
    users: [],
    companies: [],
    error: null,
    userSelected: null,
    companySelected: null,
    stock_type: null,
    alphaAndComunInv: [],
    loggedUser: null,
    loadComunAndAlpha: false,
    interest: {
      UserId: null,
      Value: null,
      CompanyId: null,
      Currency: null,
    },
    currencyOptions: [
      {
        text: "R$",
        value: CurrencyEnum.BRL,
      },
      {
        text: "US$",
        value: CurrencyEnum.USD,
      },
    ],
    UserTypeEnum,
  }),
  props: {
    Interest: Object,
    User: Object,
  },
  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    requiredNumber() {
      return (value) => value != null || this.$t("required");
    },
  },
  async created() {
    this.loggedUser = JSON.parse(localStorage.getItem("user"));

    if (this.Interest != null) {
      this.edit = true;
      this.interest = {
        Value: this.Interest.Value,
        Id: this.Interest.Id,
        Currency: this.Interest.Currency,
      };
      this.userSelected = this.Interest.User;
      this.companySelected = this.Interest.Company;
    } else {
      this.edit = false;
      this.loading = true;
      if (this.User) {
        this.users = [this.User];
        this.userSelected = this.User;
      } else {
        if (this.loggedUser.type == this.UserTypeEnum.AlphaAnalyst) {
          await this.getOnlyAlphaInvestors();
        } else if (this.loggedUser.type == this.UserTypeEnum.Admin) {
          await this.getInvestors();
        }
      }
      await this.get_ongoing_opportunities();
      this.loading = false;
    }
  },
  methods: {
    async get_ongoing_opportunities() {
      await this.apiService
        .getRequest(`company/opportunities/${this.userSelected.Id}`)
        .then((resp) => {
          let responseData = JSON.parse(resp.message);
          let allOportunities = [
            ...responseData.OngoingOpportunities,
            ...responseData.HighlightedCompanies,
            ...responseData.ExclusiveOpportunities,
          ];
          this.companies = allOportunities;
        })
        .catch((error) => {});
    },
    async getOnlyAlphaInvestors() {
      this.loading = true;
      await this.apiService
        .getRequest(`investor/list-alpha`)
        .then((resp) => {
          this.users = JSON.parse(resp);
        })
        .catch((error) => {});
      this.loading = false;
    },
    async getInvestors() {
      this.loading = true;
      await this.apiService
        .getRequest("investor/list")
        .then((response) => {
          this.users = JSON.parse(response);
        })
        .catch((error) => {});
      this.loading = false;
    },
    submit: async function () {
      var result = this.$refs.form.validate();
      if (this.loading || !result) {
        return;
      }

      this.interest.Value = parseFloat(this.interest.Value);
      this.interest.Status = 3;

      this.error = null;
      this.loading = true;
      if (this.edit) {
        await this.apiService
          .postRequest("companyinterest/edit", this.interest)
          .then((result) => {
            this.$emit("update", this.interest);
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      } else {
        this.interest.CompanyId = this.companySelected.Id;
        this.interest.UserId = this.userSelected.Id || this.userSelected.id;

        await this.apiService
          .postRequest("companyinterest/new", this.interest)
          .then((result) => {
            this.$emit("update");
          })
          .catch((error) => {
            this.error = error.body.message;
          });
      }
      this.loading = false;
    },
  },
};
</script>
